import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt, faPlayCircle, faAward, faDollarSign, faChartLine, faGamepad, faUsers, faPuzzlePiece, faListUl, faEnvelope, faMoneyBillWave, faSignOutAlt, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import ThemesbergLogo from "../assets/img/themesberg.svg";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  // Parse the roles from localStorage
  const roles = JSON.parse(localStorage.getItem("roles")) || [];

  console.log("roles users", roles);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
        <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
          <Accordion.Item eventKey={eventKey}>
            <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
            </Accordion.Button>
            <Accordion.Body className="multi-level">
              <Nav className="flex-column">
                {children}
              </Nav>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };
    
    return (
        <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
          <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
            {badgeText ? (
                <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
            ) : null}
          </Nav.Link>
        </Nav.Item>
    );
  };

  return (
      <>
        <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
          <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashbordGame.path}>
            <Image src={ReactHero} className="navbar-brand-light" />
          </Navbar.Brand>
          <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </Navbar>
        <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
          <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
            <div className="sidebar-inner px-4 pt-3">
              <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                <div className="d-flex align-items-center">
                  <div className="user-avatar lg-avatar me-4">
                    {/*<Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />*/}
                  </div>
                  <div className="d-block">
                    <h6></h6>
                    <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                      <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Déconexion
                    </Button>
                  </div>
                </div>
                <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                  <FontAwesomeIcon icon={faTimes} />
                </Nav.Link>
              </div>
              <Nav className="flex-column pt-3 pt-md-0">
                <div
                  className="d-flex justify-content-start align-items-center"
                    style={{
                      marginLeft: '10px',
                      marginBottom: '20px',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                  >
                    Thé Tip Top
                </div>
                
                {/* Pages for customer */}
                {roles.includes("ROLE_CUSTOMER") ? (
                    <>
                      <NavItem title="Tickets de Jeu" link={Routes.Logiciel.path} icon={faTicketAlt} />
                      <NavItem title="Participer au Concours" link={Routes.GameCustomer.path} icon={faPlayCircle} />
                      <NavItem title="Récompenses" link={Routes.ListPrizes.path} icon={faAward} />
                    </>
                ) : null}
                {/* End pages for customer */}
                {/* Pages for admin */}
                {roles.includes("ROLE_MANAGER") ? (
                    <>
                      <NavItem title="Gain d'un utilisateur" link={Routes.RedemPrizeUser.path} icon={faDollarSign} />
                    </>
                ) : null}
                {/* End pages for admin */}

                {/* Pages for admin */}
                {roles.includes("ROLE_ADMIN") ? (
                    <>
                      {/*<NavItem title="Statistique Système" link={Routes.DashboardOverview.path} icon={faChartLine} />*/}
                      <NavItem title="Statistique du jeux"  icon={faGamepad} link={Routes.DashbordGame.path} />
                      <NavItem title="Liste des participants" link={Routes.ListUser.path} icon={faUsers} />
                      <NavItem title="Creation du jeux"  link={Routes.CreateGame.path} icon={faPuzzlePiece} />
                      <NavItem title="Liste des jeux concours"  link={Routes.ListGame.path} icon={faListUl} />
                    </>
                ) : null}
                {/* End pages for admin */}

                {/* Pages for manager */}
                {/* End pages for manager */}

                {/*<NavItem external title="Messages" link="#" target="_blank" icon={faEnvelope} />*/}
                {/*<NavItem title="Transactions" icon={faMoneyBillWave} link={Routes.Transactions.path} />*/}
                {/*<NavItem title="Settings" icon={faCog} link={Routes.Settings.path} />*/}
                {/*<NavItem external title="Calendar" link="#" target="_blank" icon={faCalendarAlt} />*/}
                {/*<NavItem external title="Map" link="#" target="_blank" icon={faMapMarkedAlt} />*/}

                {/*<CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>*/}
                {/*  <NavItem title="Bootstrap Table" link={Routes.BootstrapTables.path} />*/}
                {/*</CollapsableNavItem>*/}

                {/*<CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>*/}
                {/*  <NavItem title="Sign In" link={Routes.Signin.path} />*/}
                {/*  <NavItem title="Sign Up" link={Routes.Signup.path} />*/}
                {/*  <NavItem title="Forgot password" link={Routes.ForgotPassword.path} />*/}
                {/*  <NavItem title="Reset password" link={Routes.ResetPassword.path} />*/}
                {/*  <NavItem title="Lock" link={Routes.Lock.path} />*/}
                {/*  <NavItem title="404 Not Found" link={Routes.NotFound.path} />*/}
                {/*  <NavItem title="500 Server Error" link={Routes.ServerError.path} />*/}
                {/*</CollapsableNavItem>*/}

                {/*<NavItem external title="Plugins" link="#" target="_blank" icon={faPlug} />*/}

                <Dropdown.Divider className="my-3 border-indigo" />

                {/*<CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={faBook}>*/}
                {/*  <NavItem title="Overview" link={Routes.DocsOverview.path} />*/}
                {/*  <NavItem title="Download" link={Routes.DocsDownload.path} />*/}
                {/*  <NavItem title="Quick Start" link={Routes.DocsQuickStart.path} />*/}
                {/*  <NavItem title="License" link={Routes.DocsLicense.path} />*/}
                {/*  <NavItem title="Folder Structure" link={Routes.DocsFolderStructure.path} />*/}
                {/*  <NavItem title="Build Tools" link={Routes.DocsBuild.path} />*/}
                {/*  <NavItem title="Changelog" link={Routes.DocsChangelog.path} />*/}
                {/*</CollapsableNavItem>*/}
                {/* <CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>
                <NavItem title="Accordion" link={Routes.Accordions.path} />
                <NavItem title="Alerts" link={Routes.Alerts.path} />
                <NavItem title="Badges" link={Routes.Badges.path} />
                <NavItem external title="Widgets" link="https://demo.themesberg.com/volt-pro-react/#/components/widgets" target="_blank" badgeText="Pro" />
                <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />
                <NavItem title="Buttons" link={Routes.Buttons.path} />
                <NavItem title="Forms" link={Routes.Forms.path} />
                <NavItem title="Modals" link={Routes.Modals.path} />
                <NavItem title="Navbars" link={Routes.Navbars.path} />
                <NavItem title="Navs" link={Routes.Navs.path} />
                <NavItem title="Pagination" link={Routes.Pagination.path} />
                <NavItem title="Popovers" link={Routes.Popovers.path} />
                <NavItem title="Progress" link={Routes.Progress.path} />
                <NavItem title="Tables" link={Routes.Tables.path} />
                <NavItem title="Tabs" link={Routes.Tabs.path} />
                <NavItem title="Toasts" link={Routes.Toasts.path} />
                <NavItem title="Tooltips" link={Routes.Tooltips.path} />
              </CollapsableNavItem> */}
                {/*<NavItem external title="Themesberg" link="#" target="_blank" image={ThemesbergLogo} />*/}
                <NavItem title="Mon profil" link={Routes.ProfilUser.path}  />
              </Nav>
            </div>
          </SimpleBar>
        </CSSTransition>
      </>
  );
};
