import React, { useState } from "react";
import { UpdateFollower } from "react-mouse-follower";
import { useCookies } from "react-cookie";
import { AnimatePresence, easeInOut, motion } from "framer-motion";
import Fanta1 from "../../assets/infuseur.png";
import Fanta2 from "../../assets/thedetox.png";
import Fanta4 from "../../assets/teasiganture.png";
import Fanta5 from "../../assets/coffredecouverte.png";
import "../examples/hero.css";  
import Footer from "../examples/Footer";
import Logo from "../../assets/logo2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
import { NavLink } from 'react-router-dom';

const SlideRight = (delay) => {
  return {
    hidden: {
      opacity: 0,
      x: 100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: delay,
        ease: easeInOut,
      },
    },
    exit: {
      opacity: 0,
      x: -50,
      transition: {
        duration: 0.2,
        ease: easeInOut,
      },
    },
  };
};

const Hero = () => {
  const [cookies, setCookie] = useCookies(['user-consent']);
  const [showCookieBanner, setShowCookieBanner] = useState(!cookies['user-consent']);

  const handleAcceptCookies = () => {
    setCookie('user-consent', 'true', { path: '/', maxAge: 60 * 60 * 24 * 30 }); 
    setShowCookieBanner(false);
  };

  const headphoneData = [
    {
      id: 1,
      image: Fanta2,
      title: "Thé détox",
      subtitle: "Purifiez et revitalisez votre corps avec notre thé détox bio aux ingrédients naturels, alliant bien-être et plaisir.",
      price: "$100",
      modal: "Détox",
      bgColor: "#C19A6B",
    },
    {
      id: 2,
      image: Fanta4,
      title: "Thé signature",
      subtitle: "Savourez l'authenticité de notre thé signature, un mélange bio né de notre savoir-faire artisanal.",
      price: "$100",
      modal: "Signature",
      bgColor: "#7BB661",
    },
    {
      id: 3,
      image: Fanta5,
      title: "Coffret découverte",
      subtitle: "Découvrez notre coffret de thés bios, incluant thés détox, infusions et mélanges signature.",
      price: "$100",
      modal: "Coffret",
      bgColor: "#D2B48C",
    },
    {
      id: 4,
      image: Fanta1,
      title: "Infuseur de thé",
      subtitle: "Optimisez chaque tasse de thé bio avec notre infuseur artisanal, conçu pour libérer pleinement les arômes.",
      price: "$40",
      modal: "Infuseur",
      bgColor: "#6F4E37",
    },
  ];

  const [activeData, setActiveData] = useState(headphoneData[0]);

  const handleActiveData = (data) => {
    setActiveData(data);
  };

  const CookieBanner = () => (
    <div className="cookie-banner fixed bottom-0 left-0 w-full bg-gray-800 text-white py-4 px-4 md:px-6 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
      <p className="text-sm text-center md:text-left">
        Nous utilisons des cookies pour améliorer votre expérience sur notre site. En continuant, vous acceptez leur utilisation.
      </p>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <button
          onClick={handleAcceptCookies}
          className="btn btn-primary bg-gray-900 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition w-full md:w-auto"
        >
          Accepter
        </button>
  
        <button
          onClick={handleAcceptCookies} // Modifier si une fonction différente est utilisée pour Refuser
          className="btn btn-primary bg-red-900 text-white px-4 py-2 rounded-md hover:bg-red-600 transition w-full md:w-auto"
        >
          Refuser
        </button>
      </div>
    </div>
  );
  

  const Navbare = () => {
    return (
      <div className="text-white py-4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="container d-flex justify-content-between align-items-center"
        >
          {/* Logo section */}
          <div>
            <img src={Logo} alt="Logo" className="img-fluid" style={{ maxWidth: "100px", filter: "invert(1)" }} />
          </div>
  
          {/* Menu section */}
          <div className="d-none d-md-flex">
            <ul className="nav gap-4">
              {/* Déplacé UpdateFollower en dehors des liens */}
              <li>
                <NavLink 
                  to={Routes.Guide.path} 
                  className="btn btn-link text-white me-3 fw-bold"
                  style={{ cursor: 'pointer', position: 'relative', zIndex: 10 }}
                >
                  GUIDE DE VALIDATION DU TICKET
                </NavLink>
              </li>
              <li>
                <NavLink 
                  to={Routes.Signin.path} 
                  className="btn btn-link text-white me-3 hover:opacity-80 transition-opacity" 
                  style={{ cursor: 'pointer', position: 'relative', zIndex: 10 }}
                >
                  <FontAwesomeIcon icon={faSignInAlt} size="lg" />
                </NavLink>
              </li>
              <li>
                <NavLink 
                  to={Routes.Signup.path} 
                  className="btn btn-link text-white hover:opacity-80 transition-opacity" 
                  style={{ cursor: 'pointer', position: 'relative', zIndex: 10 }}
                >
                  <FontAwesomeIcon icon={faUserPlus} size="lg" />
                </NavLink>
              </li>
              <UpdateFollower
                mouseOptions={{
                  backgroundColor: "white",
                  zIndex: 1,
                  followSpeed: 1.5,
                  scale: 5,
                  mixBlendMode: "difference",
                }}
              />
            </ul>
          </div>
  
          {/* Hamburger Icon */}
          {/* <div className="d-md-none">
            <FontAwesomeIcon icon={faBars} className="text-white fs-2" />
          </div> */}
        </motion.div>
      </div>
    );
  };
  
  return (
    <>
      <motion.section
        initial={{ backgroundColor: activeData.bgColor }}
        animate={{ backgroundColor: activeData.bgColor }}
        transition={{ duration: 0.8 }}
        className="bg-brandDark text-white min-h-screen"
      >
        <Navbare />

        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 min-h-[647px] gap-8">
          {/* Text Section */}
          
          <div className="flex flex-col justify-center space-y-8 md:order-1">
            <div className="space-y-5 text-center md:text-left">
              <h1 className="text-4xl font-bold">
                Thé Tip Top fête sa 10ème boutique !
              </h1>
                  {/* Subtitle with custom styling */}
              <h3 className="text-xl md:text-2xl font-medium mb-4 text-white/90">
                Rejoignez notre célébration et participez à un 
                <span className="relative inline-block px-2">
                  <span className="relative z-10">événement exceptionnel !</span>
                  <span className="absolute inset-0 bg-gray-900 rounded-lg -rotate-2"></span>
                </span>
              </h3>

              {/* Description with improved readability */}
              <p className="text-lg text-white/80 max-w-2xl leading-relaxed">
                Depuis nos débuts, nous nous sommes engagés à offrir des thés bio et artisanaux 
                de la plus haute qualité. Découvrez comment vous pouvez gagner des 
                <span className="font-medium text-white"> prix incroyables</span> !
              </p>
              <AnimatePresence mode="wait">
                <UpdateFollower
                  mouseOptions={{
                    backgroundColor: "white",
                    zIndex: 9999,
                    followSpeed: 0.5,
                    rotate: -720,
                    mixBlendMode: "difference",
                    scale: 10,
                  }}
                >
                  <motion.h1
                    key={activeData.id}
                    variants={SlideRight(0.2)}
                    initial="hidden"
                    animate="show"
                    exit="exit"
                    className="text-3xl lg:text-6xl xl:text-7xl font-bold font-handwriting text-shadow"
                  >
                    {activeData.title}
                  </motion.h1>
                </UpdateFollower>
              </AnimatePresence>

              <AnimatePresence mode="wait">
                <motion.p
                  key={activeData.id}
                  variants={SlideRight(0.4)}
                  initial="hidden"
                  animate="show"
                  exit="exit"
                  className="text-sm md:text-base leading-relaxed text-white/80"
                >
                  {activeData.subtitle}
                </motion.p>
              </AnimatePresence>

              <AnimatePresence mode="wait">
                <UpdateFollower
                  mouseOptions={{
                    backgroundColor: activeData.bgColor,
                    zIndex: 9999,
                    followSpeed: 0.5,
                    rotate: -720,
                    scale: 6,
                    backgroundElement: (
                      <div>
                        <img src={activeData.image} alt={activeData.title} />
                      </div>
                    ),
                  }}
                >
                <AnimatePresence mode="wait">
                    <UpdateFollower
                      mouseOptions={{
                        backgroundColor: activeData.bgColor,
                        zIndex: 9999,
                        followSpeed: 0.5,
                        rotate: -720,
                        scale: 6,
                        backgroundElement: (
                          <div>
                            <img src={activeData.image} alt={activeData.title} />
                          </div>
                        ),
                      }}
                    >
                      <NavLink to={Routes.Signin.path}>
                        <motion.button
                          key={activeData.id}
                          variants={SlideRight(0.6)}
                          initial="hidden"
                          animate="show"
                          exit="exit"
                          style={{ color: activeData.bgColor }}
                          className="px-6 py-3 bg-gray-900 text-white fw-bold text-lg font-medium rounded-md hover:opacity-90 transition-opacity"
                        >
                          Jouer maintenant
                        </motion.button>
                      </NavLink>
                    </UpdateFollower>
                  </AnimatePresence>
                  </UpdateFollower>
                </AnimatePresence>

              {/* Separator */}
              <div className="flex items-center justify-center md:justify-start gap-4 py-4">
                <div className="w-20 h-[1px] bg-white/60"></div>
                <p className="uppercase text-sm font-medium">Découvrez les lots à gagner</p>
                <div className="w-20 h-[1px] bg-white/60"></div>
              </div> 
              {/* Product Grid */}
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-6">
                {headphoneData.map((item) => (
                  <div
                    key={item.id}
                    onClick={() => handleActiveData(item)}
                    className="relative group cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105"
                  >
                    <div className="aspect-square bg-white/5 p-4 flex items-center justify-center">
                      <img
                        src={item.image}
                        alt={item.title}
                        className={`
                          w-full h-full object-contain transition-all duration-300
                          ${activeData.id === item.id ? 'opacity-100 scale-110' : 'opacity-50 group-hover:opacity-75'}
                        `}
                      />
                    </div>
                    <div 
                      className={`
                        absolute inset-0 transition-opacity duration-300
                        ${activeData.id === item.id ? 'opacity-20' : 'opacity-0 group-hover:opacity-10'}
                      `}
                      style={{ backgroundColor: item.bgColor }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Image Section */}
          <div className="flex flex-col justify-end items-center relative order-1 md:order-2 ">
            <AnimatePresence mode="wait">
              <motion.img
                key={activeData.id}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.4, ease: easeInOut }}
                exit={{
                  opacity: 0,
                  x: -100,
                  transition: {
                    duration: 0.4,
                  },
                }}
                src={activeData.image}
                alt={activeData.title}
                className="w-[200px] md:w-[300px] xl:w-[400px] relative z-10"
                style={{ marginBottom: "-00px" }} 
              />
            </AnimatePresence>
            
            <AnimatePresence mode="wait">
              <motion.div
                key={activeData.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4, ease: easeInOut }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 flex items-center justify-center text-white/5"
              >
                <span className="text-white/5 text-[230px] font-poppins font-extrabold absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0" >
                  {activeData.modal}
                </span>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </motion.section>
      <Footer />
      {/* Cookie Banner */}
      {showCookieBanner && <CookieBanner />}
    </>
  );
};

export default Hero;