import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Container, Card, Accordion } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

const CGV = () => {
    return (
        <main>
            <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="terms-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-800">
                                <p className="text-center">
                                    <Link to="/" className="text-gray-700">
                                        <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Retour à l'accueil
                                    </Link>
                                </p>
                                <h3 className="text-center mb-4">Conditions Générales de Vente</h3>
                                <Card className="p-4">
                                    <Card.Body>
                                        <Accordion defaultActiveKey="0">
                                            {cgvData.map((item, index) => (
                                                <Accordion.Item eventKey={String(index)} key={index}>
                                                    <Accordion.Header>{item.title}</Accordion.Header>
                                                    <Accordion.Body>{item.content}</Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};

// Données des articles de la CGV
const cgvData = [
    {
        title: "Article 1 – Objet",
        content: "Les présentes Conditions Générales de Vente (CGV) régissent les relations contractuelles entre Thé Tip Top et son client, les deux parties les acceptant sans réserve."
    },
    {
        title: "Article 2 – Produits",
        content: "Les produits proposés sont ceux qui figurent dans le catalogue publié sur le site de Thé Tip Top. Ces produits sont proposés dans la limite des stocks disponibles."
    },
    {
        title: "Article 3 – Commandes",
        content: "Le client passe commande sur le site internet de Thé Tip Top en suivant le processus de commande en ligne. Une confirmation de commande est envoyée au client par email."
    },
    {
        title: "Article 4 – Prix",
        content: "Les prix des produits sont indiqués en euros toutes taxes comprises (TTC). Thé Tip Top se réserve le droit de modifier ses prix à tout moment, étant entendu que le prix figurant au catalogue le jour de la commande sera le seul applicable au client."
    },
    {
        title: "Article 5 – Paiement",
        content: "Le paiement est exigible immédiatement à la commande. Les paiements seront effectués par carte bancaire via un système sécurisé."
    },
    {
        title: "Article 6 – Livraison",
        content: "Les livraisons sont faites à l'adresse indiquée lors de la commande. Thé Tip Top ne pourra être tenue responsable des conséquences dues à un retard d'acheminement."
    },
    {
        title: "Article 7 – Rétractation",
        content: "Conformément à la loi, le client dispose d'un délai de 14 jours à compter de la réception de sa commande pour exercer son droit de rétractation sans avoir à justifier de motifs ni à payer de pénalité."
    },
    {
        title: "Article 8 – Responsabilité",
        content: "Thé Tip Top, dans le processus de vente en ligne, n'est tenue que par une obligation de moyens ; sa responsabilité ne pourra être engagée pour un dommage résultant de l'utilisation du réseau internet tel que perte de données, intrusion, virus, rupture du service, ou autres problèmes involontaires."
    },
    {
        title: "Article 9 – Propriété Intellectuelle",
        content: "Tous les éléments du site de Thé Tip Top sont et restent la propriété intellectuelle et exclusive de Thé Tip Top."
    },
    {
        title: "Article 10 – Droit Applicable",
        content: "Les présentes CGV sont soumises à la loi française. En cas de litige, les tribunaux français seront seuls compétents."
    }
];

export default CGV;
