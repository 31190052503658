import React from "react";
import Slider from "react-slick";
import { Card } from '@themesberg/react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const gainsData = [
  {
    type: 'Infuseur à thé',
    pourcentage: 60,
    valeur: null,
    description: "Un infuseur à thé pratique pour préparer vos infusions préférées de manière simple et élégante.",
    image: "https://images.unsplash.com/photo-1604093592774-8d76fd3fdbf3?q=80&w=1587&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    type: 'Boite de 100g de thé détox ou d’infusion',
    pourcentage: 20,
    valeur: '100g',
    description: "Une boîte de 100g de thé détox ou d'infusion pour une expérience de relaxation et de bien-être.",
    image: "https://m.media-amazon.com/images/I/71RCrv7eoJL._SX679_.jpg"
  },
  {
    type: 'Boite de 100g de thé signature',
    pourcentage: 10,
    valeur: '100g',
    description: "Savourez une sélection de nos meilleurs thés signature dans une boîte de 100g.",
    image: "https://images.unsplash.com/photo-1492778297155-7be4c83960c7?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    type: 'Coffret découverte 39€',
    pourcentage: 6,
    valeur: '39€',
    description: "Découvrez une variété de thés et d'infusions avec ce coffret à 39€, idéal pour les amateurs de thé.",
    image: "https://plus.unsplash.com/premium_photo-1667635308108-e0f6770cd512?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    type: 'Coffret découverte 69€',
    pourcentage: 4,
    valeur: '69€',
    description: "Offrez-vous un coffret découverte de luxe à 69€ avec une sélection premium de thés et infusions.",
    image: "https://plus.unsplash.com/premium_photo-1681302765374-85a6681d634f?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
];

export const SliderShow = () => {
  const settings = {
    dots: true, // Afficher les points de navigation
    infinite: true, // Boucle infinie
    speed: 500, // Vitesse de transition
    slidesToShow: 1, // Nombre d'éléments affichés
    slidesToScroll: 1, // Nombre d'éléments défilés
    autoplay: true, // Activer l'autoplay
    autoplaySpeed: 3000 // Vitesse de l'autoplay
  };

  return (
      <Card border="light" className="text-center p-0 mb-4">
        <Slider {...settings}>
          {gainsData.map((gain, index) => (
              <div key={index}>
                {/* Image de couverture */}
                <div style={{ backgroundImage: `url(${gain.image})`, height: '250px', backgroundSize: 'cover', backgroundPosition: 'center' }} className="profile-cover rounded-top" />
                <Card.Body className="pb-5">
                  <Card.Title>{gain.type}</Card.Title>
                  <Card.Subtitle className="fw-normal">
                  </Card.Subtitle>
                  {gain.valeur && <Card.Text className="text-gray mb-2">Valeur: {gain.valeur}</Card.Text>}
                  <Card.Text className="text-muted mb-4">{gain.description}</Card.Text> {/* Texte descriptif */}
                </Card.Body>
              </div>
          ))}
        </Slider>
      </Card>
  );
};
