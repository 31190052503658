import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, ButtonGroup, Dropdown, Table, Badge, Pagination } from '@themesberg/react-bootstrap';
import url from "../config";

export default () => {
    const [gains, setGains] = useState([]); // État pour stocker les gains
    const [loading, setLoading] = useState(true); // État pour le chargement
    const [currentPage, setCurrentPage] = useState(1); // État pour la page actuelle
    const [itemsPerPage] = useState(10); // Nombre de gains à afficher par page

    const fetchGains = async () => {
        try {
            const token = localStorage.getItem("token");

            if (!token) {
                console.error("Aucun jeton d'autorisation trouvé.");
                return;
            }

            const response = await axios.get(`${url}/api/customer/view_prizes`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setGains(response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération des gains:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGains(); // Appel à l'API lors du montage du composant
    }, []);

    // Fonction pour déterminer le style du badge en fonction du statut de réclamation (booléen)
    const getStatusBadge = (status) => {
        return status ? (
            <Badge bg="success">Réclamé</Badge>
        ) : (
            <Badge bg="secondary">Non réclamé</Badge>
        );
    };

    // Calculer les gains actuels à afficher en fonction de la pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentGains = gains.slice(indexOfFirstItem, indexOfLastItem);

    // Fonction pour changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Générer les éléments de pagination
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(gains.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <h4>Liste de vos gains</h4>
                    <p className="mb-0">Voici un aperçu détaillé de vos gains récents et de leur évolution au fil du temps.</p>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    {/* Autres boutons ou options de filtre peuvent être ajoutés ici */}
                </div>
            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}></Col>
                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm icon-gray">
                                    <FontAwesomeIcon icon={faCog} />
                                </span>
                            </Dropdown.Toggle>
                        </Dropdown>
                    </Col>
                </Row>
            </div>

            {/* Affichage du tableau de gains */}
            {loading ? (
                <p>Chargement des gains...</p>
            ) : (
                <>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Jeu Concour</th>
                            <th>Code</th>
                            <th>Type de Gain</th>
                            <th>Status de Réclamation</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentGains.map((gain, index) => (
                            <tr key={index}>
                                <td>{indexOfFirstItem + index + 1}</td>
                                <td>{gain.ticket.jeuConcour.intitule}</td>
                                <td>{gain.ticket.code}</td>
                                <td>{gain.ticket.gain.TypeDeGain}</td>
                                <td>{getStatusBadge(gain.status_reclamation)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    {/* Pagination */}
                    <Pagination className="mt-3 d-flex justify-content-center">
                        {pageNumbers.map((number) => (
                            <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                                {number}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </>
            )}
        </>
    );
};
