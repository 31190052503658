import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faSearch, faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/user-img.png";
import manager from "../assets/img/team/manager-img.png";
import admin from "../assets/img/team/admin-img.png";
import user from "../assets/img/team/user-img.png";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";

export default (props) => {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const [userName, setUserName] = useState('');
  const [profileImage, setProfileImage] = useState(Profile3); // Image de profil par défaut
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
  const history = useHistory();

  useEffect(() => {
    // Récupérer le nom et le prénom depuis le localStorage
    const firstName = localStorage.getItem('firstName') || 'User';
    const lastName = localStorage.getItem('lastName') || '';

    // Récupérer le rôle sous forme de tableau depuis le localStorage
    const userRoles = JSON.parse(localStorage.getItem('roles')) || ["ROLE_CUSTOMER"]; // Valeur par défaut

    // Extraire le rôle principal
    const primaryRole = userRoles[0];

    setUserName(`${firstName} ${lastName}`);

    // Définir l'image en fonction du rôle principal
    switch (primaryRole) {
      case 'ROLE_ADMIN':
        setProfileImage(admin);
        break;
      case 'ROLE_MANAGER':
        setProfileImage(manager);
        break;
      case 'ROLE_CUSTOMER':
      case 'ROLE_USER': // Exemple d'autre rôle
        setProfileImage(user);
        break;
      default:
        setProfileImage(Profile3); // Image par défaut
    }
  }, []);

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };

  const logout = () => {
    localStorage.clear();
    history.push(Routes.Signin.path);
  };

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
        <ListGroup.Item action href={link} className="border-bottom border-light">
          <Row className="align-items-center">
            <Col className="ps-0 ms--2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="h6 mb-0 text-small">{sender}</h4>
                </div>
                <div className="text-end">
                  <small className={readClassName}>{time}</small>
                </div>
              </div>
              <p className="font-small mt-1 mb-0">{message}</p>
            </Col>
          </Row>
        </ListGroup.Item>
    );
  };

  return (
      <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <Form className="navbar-search">
                <Form.Group id="topbarSearch">
                  <InputGroup className="input-group-merge search-bar">
                    <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Recherche" />
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>
            <Nav className="align-items-center">
              {/*<Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>*/}
              {/*  <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">*/}
              {/*  <span className="icon icon-sm">*/}
              {/*    <FontAwesomeIcon icon={faBell} className="bell-shake" />*/}
              {/*    {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}*/}
              {/*  </span>*/}
              {/*  </Dropdown.Toggle>*/}
              {/*  <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">*/}
              {/*    <ListGroup className="list-group-flush">*/}
              {/*      <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">*/}
              {/*        Notifications*/}
              {/*      </Nav.Link>*/}

              {/*      {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}*/}

              {/*      <Dropdown.Item className="text-center text-primary fw-bold py-3">*/}
              {/*        View all*/}
              {/*      </Dropdown.Item>*/}
              {/*    </ListGroup>*/}
              {/*  </Dropdown.Menu>*/}
              {/*</Dropdown>*/}

              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    {/* Afficher l'image de profil en fonction du rôle */}
                    <Image src={profileImage} className="user-avatar md-avatar rounded-circle" />
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">{userName}</span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item className="fw-bold">
                    <FontAwesomeIcon icon={faUserCircle} link={Routes.ProfilUser.path} className="me-2" /> Mon profil
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item className="fw-bold" onClick={logout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2"/> Déconnexion
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
  );
};
