import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, ButtonGroup, Dropdown } from '@themesberg/react-bootstrap';

import ListGames from "../components/ListGames";

const ListGame = () => {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <h4>Liste des jeux concours</h4>
                    <p className="mb-0">Voici un aperçu des jeux concours.</p>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    {/* Ajoutez ici des boutons ou des actions si nécessaire */}
                </div>
            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                        {/* Ajoutez ici des éléments de filtrage ou de recherche si nécessaire */}
                    </Col>
                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm icon-gray">
                                    <FontAwesomeIcon icon={faCog} />
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/* Ajoutez ici des options de menu si nécessaire */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
            <ListGames />
        </>
    );
};

export default ListGame;