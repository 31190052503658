import React, { useState, useCallback, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, Alert } from '@themesberg/react-bootstrap';
import axios from 'axios';
import url from "../config";

export const GeneralInfoForm = () => {
  const [formData, setFormData] = useState({
    prenom: '',
    nom: '',
    birthday: '',
    genre: '',
    phone: '',
    address: '',
    addressNumber: '',
    city: '',
    state: '',
    zip: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);

  const fetchUserId = useCallback(() => {
    const id = localStorage.getItem('user_id');
    if (id) {
      setUserId(id);
    } else {
      setError('Veuillez actualiser votre page.');
    }
  }, []);

  useEffect(() => {
    fetchUserId();
  }, [fetchUserId]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleDateChange = (date) => {
    setFormData(prevData => ({
      ...prevData,
      birthday: date ? date.format("YYYY-MM-DD") : ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(null);
    setError(null);

    if (!userId) {
      setError('ID utilisateur non disponible.');
      setLoading(false);
      return;
    }
    try {
      await axios.put(`${url}/user/edit/${userId}`, {
        prenom: formData.prenom,
        nom: formData.nom,
        dateDeNaissance: formData.birthday,
        genre: formData.genre,
        phone: formData.phone,
        address: formData.address,
        addressNumber: formData.addressNumber,
        city: formData.city,
        state: formData.state,
        zip: formData.zip
      });
      setSuccess('Profil mis à jour avec succès!');
      // Actualiser la page après 2 secondes
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError('Erreur lors de la mise à jour du profil.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">Informations générales</h5>
          {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible>{success}</Alert>}
          {error && <Alert variant="danger" onClose={() => setError(null)} dismissible>{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                      required
                      type="text"
                      id="prenom"
                      placeholder="Entrez votre prénom"
                      value={formData.prenom}
                      onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Nom de famille</Form.Label>
                  <Form.Control
                      required
                      type="text"
                      id="nom"
                      placeholder="Entrez votre nom de famille"
                      value={formData.nom}
                      onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Date de naissance</Form.Label>
                  <Datetime
                      timeFormat={false}
                      value={formData.birthday ? moment(formData.birthday) : null}
                      onChange={handleDateChange}
                      renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="jj/mm/aaaa"
                                onFocus={openCalendar}
                                {...props}
                            />
                          </InputGroup>
                      )}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Genre</Form.Label>
                  <Form.Select id="genre" value={formData.genre} onChange={handleInputChange}>
                    <option value="">Sélectionnez un genre</option>
                    <option value="femme">Femme</option>
                    <option value="homme">Homme</option>
                    <option value="autre">Autre</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {/* Autres champs du formulaire... */}
            <div className="mt-3">
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? 'Enregistrement...' : 'Enregistrer'}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
  );
};