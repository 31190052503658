import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { Routes } from '../../routes';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  // Définition des liens avec des chemins par défaut
  const legalLinks = [
    { path: Routes.TermsConditions.path, text: "Politique de confidentialité" },
    { path: Routes.Cgv.path, text: "CGV" },
    { path: Routes.Cgu.path, text: "CGU" },
    { path: "/mentions-legales", text: "Mentions légales" },
  ];

  return (
    <footer className="bg-gray-900 text-gray-300 py-4">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center justify-between gap-4">
          {/* Brand and Description */}
          <div className="flex-shrink-0 max-w-xs">
            <h2 className="text-lg font-bold text-white">Thé Tip Top</h2>
            <p className="text-xs">
              Découvrez l'excellence du thé bio avec Thé Tip Top. Une sélection unique de thés d'exception.
            </p>
          </div>

          {/* Legal Links */}
          <div className="flex-shrink-0">
            <h3 className="text-sm font-semibold text-white mb-1">Informations Légales</h3>
            <div className="flex gap-4 text-xs">
              {legalLinks.map((link, index) => (
                <Link
                  key={index}
                  to={link.path}
                  className="hover:text-white transition-colors"
                >
                  {link.text}
                </Link>
              ))}
            </div>
          </div>

          {/* Newsletter */}
          <div className="flex-shrink-0">
            <div className="text-xs">
              <h3 className="text-sm font-semibold text-white mb-1">Newsletter</h3>
              <p className="mb-1">Inscrivez-vous à notre newsletter pour recevoir nos offres exclusives</p>
              <form className="flex gap-2">
                <input
                  type="email"
                  placeholder="Votre email"
                  className="px-2 py-1 text-xs rounded-md bg-gray-800 border border-gray-700 focus:outline-none focus:border-gray-600"
                />
                <button
                  type="submit"
                  className="px-3 py-1 bg-green-600 text-white text-xs rounded-md hover:bg-green-700 transition-colors"
                >
                  S'inscrire
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-6 mt-4">
          <a
            href="https://www.pinterest.fr/thetiptopkl/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white transition-colors"
          >
            <FontAwesomeIcon icon={faPinterest} size="lg" />
          </a>
          <a
            href="https://www.instagram.com/thetiptop_g2_kl/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white transition-colors"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
        </div>

        <div className="text-xs text-center mt-4 pt-2 border-t border-gray-800">
          © {currentYear} Thé Tip Top. Tous droits réservés.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
