import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt, faCopy, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, ButtonGroup } from '@themesberg/react-bootstrap';
import axios from 'axios';
import url from "../config";

// Fonction pour récupérer le code de concours
const fetchContestCode = async (token, endpoint, setCode) => {
    try {
        const response = await axios.get(`${url}/participation/${endpoint}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setCode(response.data.ticket.code);
    } catch (error) {
        console.error(`Erreur lors de la récupération du code de concours pour ${endpoint}:`, error);
        setCode('Erreur lors de la récupération');
    }
};

const ReceiptItem = ({ name, quantity, price }) => (
    <div className="d-flex justify-content-between py-1">
        <span>{name} x{quantity}</span>
        <span>{(price * quantity).toFixed(2)}€</span>
    </div>
);

const Receipt = ({ title, icon, endpoint }) => {
    const [items, setItems] = useState(generateRandomReceipt());
    const [contestCode, setContestCode] = useState('');
    const [loading, setLoading] = useState(false);

    const totalAmount = items.reduce((sum, item) => sum + item.price * item.quantity, 0).toFixed(2);

    useEffect(() => {
        if (parseFloat(totalAmount) >= 49) {
            refreshContestCode();
        }
    }, [items]);

    const generateNewPurchase = () => {
        setItems(generateRandomReceipt()); // Générer de nouveaux articles sans recharger la page
    };

    const copyContestCode = () => {
        navigator.clipboard.writeText(contestCode);
        alert('Code copié dans le presse-papiers !');
    };

    const refreshContestCode = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Aucun jeton d'autorisation trouvé.");
            setLoading(false);
            return;
        }
        await fetchContestCode(token, endpoint, setContestCode);
        setLoading(false);
    };

    return (
        <div className="bg-white p-4 rounded shadow">
            <div className="d-flex align-items-center justify-content-between mb-4">
                <h2 className="h4 font-weight-bold">{title}</h2>
                <FontAwesomeIcon icon={icon} size="lg" />
            </div>
            <div className="border-top border-bottom py-2 mb-4">
                {items.map((item, index) => (
                    <ReceiptItem key={index} {...item} />
                ))}
            </div>
            <div className="d-flex justify-content-between font-weight-bold mb-4">
                <span>Total</span>
                <span>{totalAmount}€</span>
            </div>
            {parseFloat(totalAmount) >= 49 && (
                <div className="bg-warning p-3 rounded text-center mb-4">
                    <p className="font-weight-bold mb-1">Jeu-Concours</p>
                    <p className="text-monospace d-flex justify-content-center align-items-center">
                        {loading ? 'Chargement...' : contestCode}
                        <Button variant="link" onClick={copyContestCode} className="ms-2 p-0" disabled={loading}>
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                    </p>
                </div>
            )}
            <ButtonGroup className="d-flex justify-content-center">
                <Button variant="primary" onClick={generateNewPurchase} disabled={loading}>
                    {loading ? 'Chargement...' : 'Nouvel achat'}
                </Button>
            </ButtonGroup>
            <p className="text-muted text-center small mt-3">
                Merci de votre visite! À bientôt!
            </p>
        </div>
    );
};

// Fonction pour générer une facture aléatoire avec un total supérieur à 49 €
const generateRandomReceipt = () => {
    const teaItems = [
        { name: "Thé vert Sencha", price: 15.00 },
        { name: "Thé noir Assam", price: 12.50 },
        { name: "Infusion Camomille", price: 5.00 },
        { name: "Thé blanc Bai Mu Dan", price: 20.00 },
        { name: "Thé Oolong", price: 18.00 },
    ];

    let total = 0;
    const receiptItems = [];

    while (total < 49) {
        const randomItem = teaItems[Math.floor(Math.random() * teaItems.length)];
        const randomQuantity = Math.floor(Math.random() * 3) + 1;
        const itemTotal = randomItem.price * randomQuantity;

        total += itemTotal;
        receiptItems.push({ ...randomItem, quantity: randomQuantity });
    }

    return receiptItems;
};

const SupermarketReceipts = () => {
    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <h4>Vos Reçus de caisse et pour vos achats en ligne</h4>
                    <p className="mb-0">
                        Affichez vos tickets de caisse et d'achats en ligne avec des codes de concours uniques.
                    </p>
                </div>
            </div>
            <div className="container py-4">
                <Row className="justify-content-center">
                    <Col md={6} lg={4}>
                        <Receipt title="Logiciel de caisse" icon={faReceipt} endpoint="caisse_98" />
                    </Col>
                    <Col md={6} lg={4}>
                        <Receipt title="Achat en ligne" icon={faShoppingCart} endpoint="site_web_99" />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SupermarketReceipts;
