import React, { useState } from "react";
import { UpdateFollower } from "react-mouse-follower";
import { useCookies } from "react-cookie";
import { AnimatePresence, easeInOut, motion } from "framer-motion";
import Fanta1 from "../../assets/infuseur.png";
import Fanta2 from "../../assets/thedetox.png";
import Fanta4 from "../../assets/teasiganture.png";
import Fanta5 from "../../assets/coffredecouverte.png";
import "../examples/hero.css";  
import Footer from "../examples/Footer";
import Logo from "../../assets/logo2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
import { NavLink } from 'react-router-dom';

const SlideRight = (delay) => {
  return {
    hidden: {
      opacity: 0,
      x: 100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: delay,
        ease: easeInOut,
      },
    },
    exit: {
      opacity: 0,
      x: -50,
      transition: {
        duration: 0.2,
        ease: easeInOut,
      },
    },
  };
};

const Guide = () => {
  const [cookies, setCookie] = useCookies(['user-consent']);
  const [showCookieBanner, setShowCookieBanner] = useState(!cookies['user-consent']);

  const handleAcceptCookies = () => {
    setCookie('user-consent', 'true', { path: '/', maxAge: 60 * 60 * 24 * 30 }); 
    setShowCookieBanner(false);
  };

  const headphoneData = [

  ];

  const [activeData, setActiveData] = useState(headphoneData[0]);

  const handleActiveData = (data) => {
    setActiveData(data);
  };

  const CookieBanner = () => (
    <div className="cookie-banner fixed bottom-0 left-0 w-full bg-gray-800 text-white py-4 px-4 md:px-6 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
      <p className="text-sm text-center md:text-left">
        Nous utilisons des cookies pour améliorer votre expérience sur notre site. En continuant, vous acceptez leur utilisation.
      </p>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <button
          onClick={handleAcceptCookies}
          className="btn btn-primary bg-gray-900 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition w-full md:w-auto"
        >
          Accepter
        </button>
  
        <button
          onClick={handleAcceptCookies} // Modifier si une fonction différente est utilisée pour Refuser
          className="btn btn-primary bg-red-900 text-white px-4 py-2 rounded-md hover:bg-red-600 transition w-full md:w-auto"
        >
          Refuser
        </button>
      </div>
    </div>
  );

  const Navbare = () => {
    return (
      <div className="text-white py-4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="container d-flex justify-content-between align-items-center"
        >
          {/* Logo section */}
          <div>
            <img src={Logo} alt="Logo" className="img-fluid" style={{ maxWidth: "100px", filter: "invert(1)" }} />
          </div>
  
          {/* Menu section */}
          <div className="d-none d-md-flex">
            <ul className="nav gap-4">
              {/* Déplacé UpdateFollower en dehors des liens */}
              <li>
                <NavLink 
                  to={Routes.Signin.path} 
                  className="btn btn-link text-white me-3 hover:opacity-80 transition-opacity" 
                  style={{ cursor: 'pointer', position: 'relative', zIndex: 10 }}
                >
                  <FontAwesomeIcon icon={faSignInAlt} size="lg" />
                </NavLink>
              </li>
              <li>
                <NavLink 
                  to={Routes.Signup.path} 
                  className="btn btn-link text-white hover:opacity-80 transition-opacity" 
                  style={{ cursor: 'pointer', position: 'relative', zIndex: 10 }}
                >
                  <FontAwesomeIcon icon={faUserPlus} size="lg" />
                </NavLink>
              </li>
              <UpdateFollower
                mouseOptions={{
                  backgroundColor: "white",
                  zIndex: 1,
                  followSpeed: 1.5,
                  scale: 5,
                  mixBlendMode: "difference",
                }}
              />
            </ul>
          </div>
  
          {/* Hamburger Icon */}
          {/* <div className="d-md-none">
            <FontAwesomeIcon icon={faBars} className="text-white fs-2" />
          </div> */}
        </motion.div>
      </div>
    );
  };

  return (
    <>
    <motion.section
      initial={{ backgroundColor: 'rgb(193, 154, 107)' }}
      animate={{ backgroundColor: 'rgb(193, 154, 107)' }}
      transition={{ duration: 0.8 }}
      className="bg-brandDark text-white min-h-screen"
    >
        <Navbare />

        <div className="flex items-center justify-center min-h-screen px-4 py-3">
          <div className="max-w-4xl w-full text-center">
            <div className="space-y-5">
              <div className="text-lg text-white/80 max-w-2xl leading-relaxed mx-auto">
                <h2 className="mb-4 text-4xl font-bold">Guide de Validation d'un Ticket</h2>
                <p>Voici un guide simple et détaillé pour vous accompagner dans la validation de votre ticket dans le cadre du jeu-concours organisé par <strong>Thé Tip Top</strong>.</p>

                <h3 className="text-xl font-semibold mt-6">1. Accéder au Site du Jeu-Concours</h3>
                <ul className="list-inside list-disc">
                    <li>Rendez-vous sur le site dédié au jeu-concours en suivant l’URL officielle indiquée sur votre ticket de caisse ou disponible dans nos boutiques.</li>
                    <li>Veillez à disposer d’une connexion internet stable pour éviter toute interruption.</li>
                </ul>

                <h3 className="text-xl font-semibold mt-6">2. Créer un Compte ou Se Connecter</h3>
                <ul className="list-inside list-disc">
                    <li><strong>Option 1</strong> : Se connecter avec votre compte Google ou Facebook. Cliquez sur le bouton "Se connecter avec Google" ou "Se connecter avec Facebook" et suivez les instructions.</li>
                    <li><strong>Option 2</strong> : Créer un compte manuellement. Remplissez le formulaire avec vos informations personnelles et validez votre inscription.</li>
                </ul>

                <h3 className="text-xl font-semibold mt-6">3. Entrer le Code du Ticket</h3>
                <p>Une fois connecté, accédez à la section "Valider un ticket" et saisissez le code unique de 10 caractères qui figure sur votre ticket de caisse ou votre facture.</p>

                <h3 className="text-xl font-semibold mt-6">4. Vérification et Attribution du Gain</h3>
                <p>Après validation, notre système vérifiera automatiquement la validité de votre code. Le lot associé à votre ticket sera immédiatement affiché à l’écran.</p>

                <h3 className="text-xl font-semibold mt-6">5. Consulter l'Historique des Gains</h3>
                <p>Accédez à la section "Mes gains" de votre compte pour consulter l’historique de vos gains et retrouver les détails de vos participations.</p>

                <h3 className="text-xl font-semibold mt-6">6. Récupérer Votre Lot</h3>
                <p>Deux options s’offrent à vous pour récupérer votre lot :</p>
                <ul className="list-inside list-disc">
                    <li><strong>En magasin</strong> : Rendez-vous dans l’une de nos boutiques partenaires avec le code a 10 caractères ou le bon de retrait que vous recevrez.</li>
                    <li><strong>En ligne</strong> : Si vous avez gagné un lot à retirer en ligne, un code de réduction sera envoyé à votre adresse email pour utiliser directement sur notre boutique en ligne.</li>
                </ul>

                <h3 className="text-xl font-semibold mt-6">7. Profiter de Votre Gain</h3>
                <p>Une fois votre lot récupéré, profitez pleinement de votre récompense et de l'expérience "Thé Tip Top" !</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </motion.section>
      {showCookieBanner && <CookieBanner />}
    </>
  );
};

export default Guide;
