import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import {Routes} from "../routes";

const Footer = () => {
  const currentYear = moment().get("year");

  return (
      <footer className="footer mt-auto py-4 bg-light text-center">
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <ul className="list-inline mb-3">
              <li className="list-inline-item mx-2">
                <Card.Link as={Link} to={Routes.TermsConditions.path} className="text-muted">
                  Politique de confidentialité
                </Card.Link>
              </li>
              <li className="list-inline-item mx-2">
                <Card.Link as={Link} to={Routes.Cgv.path}  className="text-muted">
                  CGV
                </Card.Link>
              </li>
              <li className="list-inline-item mx-2">
                <Card.Link as={Link} to={Routes.Cgu.path}  className="text-muted">
                  CGU
                </Card.Link>
              </li>
            </ul>
            <p className="small text-muted mb-0">
              &copy; {currentYear} Thé Tip Top. Tous droits réservés.
            </p>
          </Col>
        </Row>
      </footer>
  );
};

export default Footer;
