import React from "react";
import { Col, Row } from '@themesberg/react-bootstrap';
import {Game} from "../components/Game";
import {SliderShow} from "../components/SliderShow";


export default () => {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-flex">
                </div>
            </div>

            <Row>
                <Col xs={12} xl={8}>
                    <Game />
                </Col>

                <Col xs={12} xl={4}>
                    <Row>
                        <Col xs={12}>
                            <SliderShow />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
