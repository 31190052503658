import React, { useState } from "react";
import axios from "axios";
import {useHistory} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, Alert, Spinner } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import url from "../config";

export const Game = () => {
  const [ticketCode, setTicketCode] = useState("");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [participationData, setParticipationData] = useState(null);
  const navigate = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setShowAlert(true);

    if (ticketCode.length !== 10) {
      setMessage("Le code du ticket doit contenir exactement 10 caractères.");
      setVariant("warning");
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        setMessage("Veuillez vous connecter pour valider le ticket.");
        setVariant("danger");
        setLoading(false);
        return;
      }

      const response = await axios.get(`${url}/api/customer/participate/${ticketCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setParticipationData(response.data.participate);
        setMessage("Participation au jeu concours réussie !");
        setVariant("success");
        setTimeout(() => {
          navigate.push(Routes.ListPrizes.path);
        }, 3000);
      }
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            setMessage("Utilisateur non authentifié. Veuillez vous connecter.");
            break;
          case 403:
            setMessage("Accès non autorisé. Vous n'avez pas les droits nécessaires.");
            break;
          case 404:
            setMessage("Le code de ticket n'existe pas.");
            break;
          case 409:
            setMessage("Le code de votre ticket a déjà été validé ou est déjà enregistré.");
            break;
          default:
            setMessage("Erreur lors de la participation. Veuillez réessayer.");
        }
        setVariant("danger");
      } else {
        setMessage("Erreur lors de la participation. Veuillez réessayer.");
        setVariant("danger");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">Formulaire de validation de votre ticket</h5>

          {showAlert && message && (
              <Alert
                  variant={variant}
                  onClose={() => setShowAlert(false)}
                  dismissible
              >
                {message}
              </Alert>
          )}

          {loading && (
              <div className="d-flex justify-content-center mb-3">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Chargement...</span>
                </Spinner>
              </div>
          )}

          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="ticketCode">
                  <Form.Label>Code de votre ticket</Form.Label>
                  <Form.Control
                      required
                      type="text"
                      placeholder="Code du ticket"
                      value={ticketCode}
                      onChange={(e) => setTicketCode(e.target.value)}
                      maxLength={10}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-3">
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? "Validation en cours..." : "Valider votre ticket"}
              </Button>
            </div>
          </Form>

          {participationData && (
              <div className="mt-4">
                <Card border="light" className="bg-light shadow-sm">
                  <Card.Body>
                    <h5>Ticket Validé</h5>
                    <p><strong>Code du ticket:</strong> {participationData.code}</p>
                    <p><strong>Intitulé du jeu:</strong> {participationData.jeuConcour.intitule}</p>
                    <p><strong>Type de gain:</strong> {participationData.gain.TypeDeGain}</p>
                  </Card.Body>
                </Card>
              </div>
          )}
        </Card.Body>
      </Card>
  );
};