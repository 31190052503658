import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Container, Card } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";

const TermsConditions = () => {
    return (
        <main>
            <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="terms-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-800">
                                <p className="text-center">
                                    <Link to={Routes.Signin.path} className="text-gray-700">
                                        <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Retour à l'accueil
                                    </Link>
                                </p>
                                <h3 className="text-center mb-4">Termes et Conditions</h3>
                                <Card className="p-4">
                                    <Card.Body>
                                        <h4>1. Introduction</h4>
                                        <p>Bienvenue sur notre site Web. En accédant à notre site Web, vous acceptez de vous conformer à ces termes et conditions. Si vous n'acceptez pas ces termes, veuillez ne pas utiliser notre site Web.</p>

                                        <h4>2. Utilisation du Site Web</h4>
                                        <p>Vous ne devez pas utiliser ce site Web de manière illégale ou de manière à porter atteinte aux droits de quelqu'un d'autre. Vous devez également vous conformer à toutes les lois et réglementations applicables.</p>

                                        <h4>3. Propriété Intellectuelle</h4>
                                        <p>Tout le contenu et le matériel sur ce site Web, y compris les textes, images, logos, et autres éléments, sont protégés par les droits d'auteur et autres droits de propriété intellectuelle.</p>

                                        <h4>4. Limitation de Responsabilité</h4>
                                        <p>Nous ne serons pas responsables des pertes ou dommages résultant de l'utilisation de ce site Web, y compris les dommages directs, indirects, accessoires, spéciaux ou conséquents.</p>

                                        <h4>5. Modifications des Termes</h4>
                                        <p>Nous nous réservons le droit de modifier ces termes et conditions à tout moment. Toute modification prendra effet immédiatement après sa publication sur ce site Web.</p>

                                        <h4>6. Contact</h4>
                                        <p>Pour toute question concernant ces termes et conditions, veuillez nous contacter à l'adresse suivante : support@votresite.com</p>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default TermsConditions;
