import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Col, Row, Card, Form, Button, InputGroup, Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import {Routes} from "../routes";
import {useHistory} from "react-router-dom";
import url from "../config";

const FormCreateGame = () => {
    const [formData, setFormData] = useState({
        intitule: '',
        dateDebut: '',
        duree: '',
        nombreDeTicket: '',
        code_game: '',
    });

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        console.log("storedToken" + storedToken)
        if (storedToken) {
            setToken(storedToken);
        } else {
            setError("Veuillez vous connecter pour accéder à cette fonctionnalité.");
        }
    }, []);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(null);
        setError(null);

        if (!token) {
            setError("Token d'authentification manquant. Veuillez vous reconnecter.");
            setLoading(false);
            return;
        }

        try {
            const formattedData = {
                ...formData,
                dateDebut: formatDate(formData.dateDebut)
            };

            console.log("formattedData", formattedData);
            const response = await axios.post(
                `${url}/api/admin/jeux_concours`,
                formattedData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setSuccess('Jeu concours créé avec succès!');
            setFormData({
                intitule: '',
                dateDebut: '',
                duree: '',
                nombreDeTicket: '',
                code_game: '',
            });
            setTimeout(() => {
                history.push(Routes.ListGame.path);
            }, 2000);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.errors) {
                const errors = err.response.data.errors.map((e) => e.message || 'Erreur inconnue').join(', ');
                setError(errors);
            } else {
                setError('Erreur lors de la création du jeu concours.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
                <h5 className="mb-4">Création d'un Jeu Concours</h5>
                {success && (
                    <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                        {success}
                    </Alert>
                )}
                {error && (
                    <Alert variant="danger" onClose={() => setError(null)} dismissible>
                        {error}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label htmlFor="intitule">Intitulé</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    id="intitule"
                                    placeholder="Entrez l'intitulé du jeu concours"
                                    value={formData.intitule}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label htmlFor="dateDebut">Date de début</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        required
                                        type="date"
                                        id="dateDebut"
                                        value={formData.dateDebut}
                                        onChange={handleInputChange}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="mb-3">
                            <Form.Group>
                                <Form.Label htmlFor="duree">Durée (en jours)</Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    id="duree"
                                    min="1"
                                    placeholder="Entrez la durée"
                                    value={formData.duree}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group>
                                <Form.Label htmlFor="nombreDeTicket">Nombre de tickets</Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    id="nombreDeTicket"
                                    min="1"
                                    placeholder="Entrez le nombre de tickets"
                                    value={formData.nombreDeTicket}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group>
                                <Form.Label htmlFor="code_game">Code du jeu</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    id="code_game"
                                    placeholder="Entrez le code du jeu"
                                    value={formData.code_game}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="primary" type="submit" disabled={loading || !token}>
                        {loading ? 'Création en cours...' : 'Créer le jeu concours'}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default FormCreateGame;