import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert } from '@themesberg/react-bootstrap';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';

import { Routes } from "../../routes";
import url from "../../config";

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(1);
  const [alert, setAlert] = useState({ show: false, message: '', variant: 'success' });
  const history = useHistory();

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${url}/forgot-password`, { email });
      setAlert({ show: true, message: 'Un code a été envoyé à votre email.', variant: 'success' });
      setStep(2); // Move to the next step
    } catch (error) {
      console.error("Error submitting email:", error);
      setAlert({ show: true, message: 'Erreur lors de l\'envoi de l\'email.', variant: 'danger' });
    }
  };

  const handleSubmitNewPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setAlert({ show: true, message: 'Les mots de passe ne correspondent pas.', variant: 'danger' });
      return;
    }
    try {
      await axios.post(`${url}/reset_password`, { password: newPassword, code });
      setAlert({ show: true, message: 'Mot de passe réinitialisé avec succès !', variant: 'success' });
      history.push(Routes.Signin.path);
      setStep(1); // Reset step or redirect
    } catch (error) {
      console.error("Error resetting password:", error);
      setAlert({ show: true, message: 'Erreur lors de la réinitialisation du mot de passe.', variant: 'danger' });
    }
  };

  return (
      <main>
        <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
          <Container>
            <Row className="justify-content-center">
              <p className="text-center">
                <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                  <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Retour à la connexion
                </Card.Link>
              </p>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  {step === 1 ? (
                      <>
                        <h3>Mot de passe oublié ?</h3>
                        <p className="mb-4">Pas de panique ! Entrez simplement votre email et nous vous enverrons un code pour réinitialiser votre mot de passe.</p>
                        <Form onSubmit={handleSubmitEmail}>
                          <div className="mb-4">
                            <Form.Label htmlFor="email">Votre Email</Form.Label>
                            <InputGroup id="email">
                              <Form.Control
                                  required
                                  autoFocus
                                  type="email"
                                  placeholder="john@company.com"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          <Button variant="primary" type="submit" className="w-100">
                            Réinitialiser le mot de passe
                          </Button>
                        </Form>
                      </>
                  ) : (
                      <>
                        <h3>Réinitialiser le mot de passe</h3>
                        <p className="mb-4">Entrez le code reçu par email et votre nouveau mot de passe.</p>
                        <Form onSubmit={handleSubmitNewPassword}>
                          <div className="mb-4">
                            <Form.Label htmlFor="code">Code de vérification</Form.Label>
                            <InputGroup id="code">
                              <Form.Control
                                  required
                                  type="text"
                                  placeholder="Code reçu"
                                  value={code}
                                  onChange={(e) => setCode(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          <div className="mb-4">
                            <Form.Label htmlFor="new-password">Nouveau mot de passe</Form.Label>
                            <InputGroup id="new-password">
                              <Form.Control
                                  required
                                  type="password"
                                  placeholder="Nouveau mot de passe"
                                  value={newPassword}
                                  onChange={(e) => setNewPassword(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          <div className="mb-4">
                            <Form.Label htmlFor="confirm-password">Confirmez le nouveau mot de passe</Form.Label>
                            <InputGroup id="confirm-password">
                              <Form.Control
                                  required
                                  type="password"
                                  placeholder="Confirmez le mot de passe"
                                  value={confirmPassword}
                                  onChange={(e) => setConfirmPassword(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          <Button variant="primary" type="submit" className="w-100">
                            Réinitialiser le mot de passe
                          </Button>
                        </Form>
                      </>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Alert Notifications */}
        {alert.show && (
            <div className="position-fixed top-0 end-0 p-3">
              <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
                {alert.message}
              </Alert>
            </div>
        )}
      </main>
  );
}
