import React from 'react';
import { Line, Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Title, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from 'chart.js';

// Enregistre les éléments dans ChartJS pour les graphiques
ChartJS.register(Tooltip, Title, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, BarElement);

export const SalesValueChart = () => {
  const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Sales',
        data: [1, 2, 2, 3, 3, 4, 3],
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
      x: { display: true },
      y: { display: true, ticks: { callback: (value) => `$${value}k` } },
    },
  };

  return <Line data={data} options={options} />;
};

export const SalesValueChartphone = () => {
  const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Sales',
        data: [1, 2, 2, 3, 3, 4, 3],
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
      x: { display: true },
      y: { display: true, ticks: { callback: (value) => `$${value}k` } },
    },
  };

  return <Line data={data} options={options} />;
};

export const CircleChart = (props) => {
  const { series = [] } = props;
  const total = series.reduce((a, b) => a + b, 0);

  const data = {
    labels: series.map((_, i) => `Data ${i + 1}`),
    datasets: [
      {
        data: series,
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${Math.round((context.raw / total) * 100)}%`
        }
      }
    }
  };

  return <Pie data={data} options={options} />;
};

export const BarChart = (props) => {
  const { labels = [], series = [] } = props;

  const data = {
    labels,
    datasets: [
      {
        label: 'Sales',
        data: series,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
      x: { display: true },
      y: { display: true, ticks: { callback: (value) => `$${value}k` } },
    },
  };

  return <Bar data={data} options={options} />;
};
