import React, { useEffect, useState } from "react";
import { Table, Card, Button, Pagination, Spinner, Alert } from "@themesberg/react-bootstrap";
import axios from "axios";
import url from "../config";

const ParticipantsTable = () => {
    const [participants, setParticipants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [message, setMessage] = useState("");
    const [variant, setVariant] = useState("danger"); // Pour gérer l'affichage des messages d'erreur ou de succès
    const participantsPerPage = 10; // Constant value, no need to use state here

    // Charger les participants depuis l'API
    useEffect(() => {
        const fetchParticipants = async () => {
            setLoading(true);
            const token = localStorage.getItem("token");

            if (!token) {
                setMessage("Veuillez vous connecter pour accéder aux données des participants.");
                setVariant("danger");
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get(`${url}/api/admin/export_data_for_emailing`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("response:", response);
                setParticipants(response.data.data_for_emailing || []);
                setMessage("");
            } catch (error) {
                console.error("Erreur lors du chargement des participants:", error);
                setMessage("Erreur lors du chargement des participants.");
                setVariant("danger");
            } finally {
                setLoading(false);
            }
        };

        fetchParticipants();
    }, []);

    // Calculer les données pour la pagination
    const indexOfLastParticipant = currentPage * participantsPerPage;
    const indexOfFirstParticipant = indexOfLastParticipant - participantsPerPage;
    const currentParticipants = participants.slice(indexOfFirstParticipant, indexOfLastParticipant);

    // Changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Fonction pour exporter les emails
    const exportEmails = () => {
        const emails = participants.map(participant => participant.email).join("\n");
        const blob = new Blob([emails], { type: "text/plain" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "emails.txt";
        link.click();
    };

    return (
        <Card border="light" className="shadow-sm">
            <Card.Header>
                <h5>Participants au Jeu Concours</h5>
                <Button variant="primary" onClick={exportEmails} className="mt-2">
                    Exporter les Emails
                </Button>
            </Card.Header>
            <Card.Body className="pt-0">
                {/* Afficher le message d'erreur ou de succès */}
                {message && (
                    <Alert variant={variant} className="mt-2">
                        {message}
                    </Alert>
                )}
                {loading ? (
                    <div className="text-center my-4">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <Table responsive className="table-centered table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">Nom</th>
                            <th className="border-0">Prénom</th>
                            <th className="border-0">Email</th>
                            <th className="border-0">Date de Naissance</th>
                            <th className="border-0">Genre</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentParticipants.length > 0 ? (
                            currentParticipants.map((participant, index) => (
                                <tr key={index}>
                                    <td>{participant.nom || "N/A"}</td>
                                    <td>{participant.prenom || "N/A"}</td>
                                    <td>{participant.email || "N/A"}</td>
                                    <td>{participant.dateNaissance || "N/A"}</td>
                                    <td>{participant.genre || "N/A"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">Aucun participant trouvé.</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                )}

                {/* Pagination */}
                <Pagination className="mt-3 justify-content-center">
                    {[...Array(Math.ceil(participants.length / participantsPerPage)).keys()].map(number => (
                        <Pagination.Item
                            key={number + 1}
                            active={number + 1 === currentPage}
                            onClick={() => paginate(number + 1)}
                        >
                            {number + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </Card.Body>
        </Card>
    );
};

export default ParticipantsTable;
