import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Table, Card, Form, Alert, Pagination, Badge, Modal } from '@themesberg/react-bootstrap';
import url from "../config";

const UserPrizesManagement = () => {
    const [formData, setFormData] = useState({ email: "" });
    const [gains, setGains] = useState([]);
    const [selectedCodes, setSelectedCodes] = useState(new Set());
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [managerCode, setManagerCode] = useState("");
    const [customerCode, setCustomerCode] = useState("");

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevData => ({ ...prevData, [id]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);
        setGains([]);

        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("Aucun jeton d'autorisation trouvé.");
            }

            const response = await axios.get(`${url}/api/manager/view_prizes_user/${formData.email}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setGains(response.data);
            setSuccess("Liste des gains !");
        } catch (err) {
            setError(`Erreur lors de la récupération des gains: ${err.response?.data?.message || err.message}`);
        } finally {
            setLoading(false);
        }
    };


    const handleSelectCode = (code) => {
        setSelectedCodes(prev => {
            const newSelection = new Set(prev);
            if (newSelection.has(code)) {
                newSelection.delete(code);
            } else {
                newSelection.add(code);
            }
            return newSelection;
        });
    };

    const handleSendSelectedCodes = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("Aucun jeton d'autorisation trouvé.");
            }

            await axios.post(
                `${url}/api/manager/redeemPrize/${formData.email}/${localStorage.getItem("email")}`,
                {
                    ticker_codes: Array.from(selectedCodes),
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            const response = await axios.get(`${url}/api/manager/view_prizes_user/${formData.email}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setGains(response.data);
            setSelectedCodes(new Set());
            setSuccess("Les tickets ont été validés avec succès !");
            setShowModal(true);
        } catch (err) {
            setError(`Erreur lors de l'envoi des tickets: ${err.response?.data?.message || err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const getAuthToken = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            throw new Error("Aucun jeton d'autorisation trouvé. Veuillez vous connecter.");
        }
        return token;
    };

    const handleConfirmReceipt = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("Aucun jeton d'autorisation trouvé.");
            }

            // Payload à envoyer dans la requête PUT
            const payload = {
                ticker_codes: Array.from(selectedCodes), // Liste des codes sélectionnés
                code_signature_manager: managerCode, // Code du manager
                code_signature_customer: customerCode, // Code du client
            };

            // Requête PUT vers l'API de confirmation de la réception des gains
            await axios.put(
                `${url}/api/manager/confirmationOfReceiptOfPrize`,
                payload,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            setSuccess("La réception des gains a été confirmée !");
            setSelectedCodes(new Set()); // Réinitialiser les codes sélectionnés
            setManagerCode(""); // Réinitialiser le code du manager
            setCustomerCode(""); // Réinitialiser le code du client
            setShowModal(false); // Fermer la modale
        } catch (err) {
            // Gérer les erreurs en affichant le message approprié
            setError(`Erreur lors de la confirmation de la réception des gains: ${err.response?.data?.message || err.message}`);
        } finally {
            setLoading(false); // Désactiver le chargement
        }
    };

    const handleConfirmReceiptd = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("Aucun jeton d'autorisation trouvé.");
            }

            await axios.put(
                `${url}/api/manager/confirmationOfReceiptOfPrize`,
                {
                    ticker_codes: Array.from(selectedCodes),
                    code_signature_manager: managerCode,
                    code_signature_customer: customerCode,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            setSuccess("La réception des gains a été confirmée !");
            setSelectedCodes(new Set());
            setManagerCode("");
            setCustomerCode("");
            setShowModal(false);
        } catch (err) {
            //setError(`Erreur lors de la confirmation de la réception des gains: ${err.response?.data?.message || err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = gains.slice(indexOfFirstItem, indexOfLastItem);
    const pageCount = Math.ceil(gains.length / itemsPerPage);

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <h4>Gestion des Gains des Utilisateurs</h4>
                    <p className="mb-0">Cette page vous permet de visualiser et de gérer les gains des utilisateurs de votre plateforme.
                        Vous pouvez récupérer les gains d'un utilisateur en entrant son adresse e-mail, sélectionner les tickets à Valider,
                        et confirmer la réception des gains avec les codes de signature appropriés.</p>
                </div>
            </div>

            <Card border="light" className="bg-white shadow-sm mb-4">
                <Card.Body>
                    {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible>{success}</Alert>}
                    {error && <Alert variant="danger" onClose={() => setError(null)} dismissible>{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        id="email"
                                        placeholder="Entrez l'email de l'utilisateur"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="mt-3">
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? 'Chargement...' : 'Lister les gains du client'}
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <div className="table-settings m-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={6} md={6} lg={3} xl={4}>
                        {/* Il est possible d'ajouter d'autres éléments ici si nécessaire */}
                    </Col>
                    <Col xs={6} md={6} lg={3} xl={4} className="text-end">
                        <Button
                            variant="primary"
                            onClick={handleSendSelectedCodes}
                            disabled={selectedCodes.size === 0}
                        >
                            Valider le retrait des gains
                        </Button>
                    </Col>
                </Row>
            </div>

            {loading ? (
                <p>Chargement des gains...</p>
            ) : (
                <>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Sélection</th>
                            <th>Jeu Concours</th>
                            <th>Code</th>
                            <th>Type de Gain</th>
                            <th>Statut de Réclamation</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentItems.map((gain, index) => (
                            <tr key={gain.ticket.id}>
                                <td>{index + 1 + indexOfFirstItem}</td>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        checked={selectedCodes.has(gain.ticket.code)}
                                        onChange={() => handleSelectCode(gain.ticket.code)}
                                        disabled={gain.status_reclamation} // Désactiver la case si déjà réclamé
                                    />
                                </td>
                                <td>{gain.ticket.jeuConcour.intitule}</td>
                                <td>{gain.ticket.code}</td>
                                <td>{gain.ticket.gain.TypeDeGain}</td>
                                <td>
                                    <Badge bg={gain.status_reclamation ? "success" : "danger"}>
                                        {gain.status_reclamation ? "Réclamé" : "Non réclamé"}
                                    </Badge>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-center mt-3">
                        <Pagination>
                            {Array.from({ length: pageCount }, (_, index) => (
                                <Pagination.Item key={index + 1} active={index + 1 === currentPage}
                                                 onClick={() => setCurrentPage(index + 1)}>
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </div>
                </>
            )}

            {/* Modale pour entrer les codes de signature */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation des Codes de Signature</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Code Signature Manager</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrez le code signature du manager"
                                value={managerCode}
                                onChange={(e) => setManagerCode(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Code Signature Client</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrez le code signature du client"
                                value={customerCode}
                                onChange={(e) => setCustomerCode(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleConfirmReceipt}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UserPrizesManagement;
