import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Spinner, Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import ProfileCover from "../assets/img/profile-cover.jpg";
import Profile1 from "../assets/img/team/profile-picture-1.jpg";
import axios from 'axios';
import url from "../config";

export const ProfileCard = () => {
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const  idUser = localStorage.getItem('user_id');

            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.get(`${url}/user/data_user/${idUser}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setProfileData(response.data);
            setError(null);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                setError('Utilisateur non authentifié ou session expirée. Veuillez vous reconnecter.');
            } else {
                setError('Erreur de chargement du profil: ' + (err.message || 'Erreur inconnue'));
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (loading) return <Spinner animation="border" />;
    if (error) return <Alert variant="danger">{error}</Alert>;
    if (!profileData) return <Alert variant="warning">Aucune donnée de profil disponible</Alert>;

    return (
        <Card border="light" className="text-center p-0 mb-4">
            <div style={{ backgroundImage: `url(${ProfileCover})` }} className="profile-cover rounded-top" />
            <Card.Body className="pb-5">
                <Card.Img src={Profile1} alt={`${profileData.prenom} ${profileData.nom}`} className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" />
                <Card.Title>{`${profileData.prenom} ${profileData.nom}`}</Card.Title>
                <Card.Subtitle className="fw-normal">{profileData.email}</Card.Subtitle>
                <Card.Text className="text-gray mb-4">
                    {profileData.dateDeNaissance ? `Date de naissance: ${new Date(profileData.dateDeNaissance).toLocaleDateString()}` : 'Date de naissance non spécifiée'}
                </Card.Text>
                <Card.Text className="text-gray mb-4">
                    {profileData.genre ? `Genre: ${profileData.genre}` : 'Genre non spécifié'}
                </Card.Text>
                <Card.Text className="text-gray mb-4">
                    {profileData.telephone ? `Téléphone: ${profileData.telephone}` : 'Téléphone non spécifié'}
                </Card.Text>
                <Card.Text className="text-gray mb-4">
                    {profileData.adresse ? `Adresse: ${profileData.adresse}` : 'Adresse non spécifiée'}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};