import React, { useEffect } from "react";
import { Routes } from "../routes";
import { useHistory } from "react-router-dom";

export const Permission = ({ role, children }) => {
    const roles = JSON.parse(localStorage.getItem('roles')) || []; // Parse les rôles
    const history = useHistory();

    useEffect(() => {
        if (!roles.includes(role)) {
            history.push(Routes.Signin.path);
        }
    }, [roles, role, history]); // Dépendances pour l'effet

    return (
        <>
            {/* Affiche les enfants seulement si le rôle est présent */}
            {roles.includes(role) ? children : null}
        </>
    );
};
