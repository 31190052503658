import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {faFacebookF, faGithub, faGoogle, faTwitter} from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Alert } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from "axios";

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import ReactHero from "../../assets/img/technologies/react-hero-logo.svg";
import Footer from "../../components/Footer";
import url from "../../config";

// Import du composant Footer

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (error || success) {
      const timer = setTimeout(() => {
        setError("");
        setSuccess("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error, success]);

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${url}/api/login`, {
        email,
        password
      });

      console.log("Connexion réussie :", response.data);

      // Stocker le token et d'autres informations dans le stockage local
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user_id", response.data.user_id);
      localStorage.setItem("roles", JSON.stringify(response.data.roles));
      localStorage.setItem("email", response.data.email);
      localStorage.setItem("token_type", response.data.token_type);
      localStorage.setItem("firstName", response.data.nom);
      localStorage.setItem("lastName", response.data.prenom);

      setError("");
      setSuccess("Connexion réussie. Redirection en cours...");

      const userRoles = JSON.parse(localStorage.getItem("roles") || "[]");

      setTimeout(() => {
        if (userRoles.includes("ROLE_CUSTOMER")) {
          history.push(Routes.GameCustomer.path);
        } else if (userRoles.includes("ROLE_ADMIN")) {
          history.push(Routes.DashbordGame.path);
        } else {
          // Redirection par défaut si aucun rôle reconnu n'est trouvé
          history.push(Routes.RedemPrizeUser.path);
        }
      }, 2000);
    } catch (err) {
      console.error("Erreur de connexion:", err);
      if (err.response && err.response.data) {
        // Afficher des erreurs spécifiques basées sur la réponse de l'API
        setError(err.response.data.message || "Échec de la connexion. Vérifiez vos informations.");
      } else {
        setError("Erreur de connexion au serveur. Veuillez réessayer plus tard.");
      }
      setSuccess("");
    }
  };

  return (
      <main>
        {/* Section d'alertes */}
        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 5 }}>
          {error && <Alert variant="danger" className="text-center">{error}</Alert>}
          {success && <Alert variant="success" className="text-center">{success}</Alert>}
        </div>

        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <img
                        src={ReactHero}
                        alt="Hero"
                        className="img-fluid mx-auto d-block mb-4"
                        style={{ maxWidth: '100px', height: 'auto' }} // Ajoutez un style en ligne pour la taille
                    />
                  </div>
                  <Form className="mt-4" onSubmit={handleLogin}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Votre Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                            autoFocus
                            required
                            type="email"
                            placeholder="example@company.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Votre Mot de Passe</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                            required
                            type="password"
                            placeholder="Mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0"></FormCheck.Label>
                      </Form.Check>
                      <Card.Link as={Link} to={Routes.ForgotPassword.path} className="small text-end">Mot de passe oublié ?</Card.Link>
                    </div>
                    <Button variant="primary" type="submit" className="w-100">
                      Connexion
                    </Button>
                  </Form>

                  <div className="mt-3 mb-4 text-center">
                    <span className="fw-normal">ou connectez-vous avec</span>
                  </div>
                  <div className="d-flex justify-content-center my-4">
                    <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Button>
                    <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                      <FontAwesomeIcon icon={faGoogle} />
                    </Button>
                    {/*<Button variant="outline-light" className="btn-icon-only btn-pill text-dark">*/}
                    {/*  <FontAwesomeIcon icon={faGithub} />*/}
                    {/*</Button>*/}
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Pas encore inscrit ?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Créez un compte `}
                    </Card.Link>
                  </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*<Footer />*/}
      </main>
  );
};

export default Signin;
