
import { faDesktop, faMobileAlt, faTabletAlt } from '@fortawesome/free-solid-svg-icons';

const trafficShares = [
    { id: 1, label: "Desktop", value: 60, color: "secondary", icon: faDesktop },
    { id: 2, label: "Mobile Web", value: 30, color: "primary", icon: faMobileAlt },
    { id: 3, label: "Tablet Web", value: 10, color: "tertiary", icon: faTabletAlt }
];

const totalOrders = [
    { id: 1, label: "July", value: [1, 5, 2, 5, 4, 3], color: "primary" },
    { id: 2, label: "August", value: [2, 3, 4, 8, 1, 2], color: "secondary" }
];
// Répartition des gains des tickets
const ticketRewards = [
    { id: 1, label: "Infuseur à thé", value: 60, description: "60% des tickets : infuseur à thé", color: "primary" },
    { id: 2, label: "Thé détox ou infusion (100g)", value: 20, description: "20% des tickets : 100g de thé détox ou infusion", color: "secondary" },
    { id: 3, label: "Thé signature (100g)", value: 10, description: "10% des tickets : 100g de thé signature", color: "tertiary" },
    { id: 4, label: "Coffret découverte (39€)", value: 6, description: "6% des tickets : coffret à 39€", color: "success" },
    { id: 5, label: "Coffret découverte (69€)", value: 4, description: "4% des tickets : coffret à 69€", color: "danger" }
];

export {
    trafficShares,
    totalOrders,
    ticketRewards
};