import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory , Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {faFacebookF, faGithub, faGoogle, faTwitter} from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Alert } from '@themesberg/react-bootstrap';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import url from "../../config";

export default () => {
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const history = useHistory(); // Utilisation de useHistory pour la redirection

  useEffect(() => {
    if (error || success) {
      const timer = setTimeout(() => {
        setError("");
        setSuccess("");
      }, 5000); // Durée de 5 secondes pour les alertes

      return () => clearTimeout(timer);
    }
  }, [error, success]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas");
      setSuccess("");
      return;
    }

    try {
      const response = await axios.post(`${url}/register`, {
        email,
        nom,
        prenom,
        password,
        roles: ["ROLE_CUSTOMER"]
      });

      console.log("Inscription réussie :", response.data);
      setIsRegistered(true);
      setSuccess("Inscription réussie ! Un code de vérification a été envoyé à votre adresse e-mail.");
      setError("");

    } catch (err) {
      console.error("Erreur lors de l'inscription :", err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError("Erreur lors de l'inscription. Veuillez réessayer.");
      }
      setSuccess("");
    }
  };

  const handleVerification = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.put(`${url}/active_account`, {
        email: email,
        code: verificationCode
      });

      console.log("Compte vérifié :", response.data);
      setSuccess("Votre compte a été vérifié avec succès !");
      setError("");

      // Rediriger après vérification réussie
      setTimeout(() => {
        history.push(Routes.Signin.path);
      }, 2000);

    } catch (err) {
      console.error("Erreur lors de la vérification :", err);
      setError("Code de vérification incorrect ou expiré.");
      setSuccess("");
    }
  };

  return (
      <main>
        {/* Section d'alertes */}
        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 5 }}>
          {error && <Alert variant="danger" className="text-center">{error}</Alert>}
          {success && <Alert variant="success" className="text-center">{success}</Alert>}
        </div>

        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Création du compte</h3>
                  </div>

                  {!isRegistered ? (
                      // Formulaire d'inscription
                      <Form className="mt-4" onSubmit={handleSubmit}>
                        <Form.Group id="email" className="mb-4">
                          <Form.Label>Email</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>
                            <Form.Control
                                autoFocus
                                required
                                type="email"
                                placeholder="votremail@messagerie.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group id="nom" className="mb-4">
                          <Form.Label>Nom</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>
                            <Form.Control
                                autoFocus
                                required
                                type="text"
                                placeholder="Entrez votre nom"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group id="prenom" className="mb-4">
                          <Form.Label>Prénom</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>
                            <Form.Control
                                autoFocus
                                required
                                type="text"
                                placeholder="Entrez votre prénom"
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group id="password" className="mb-4">
                          <Form.Label>Mot de passe</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                type="password"
                                placeholder="Mot de passe"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group id="confirmPassword" className="mb-4">
                          <Form.Label>Confirmez le mot de passe</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                type="password"
                                placeholder="Confirmez le mot de passe"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <FormCheck type="checkbox" className="d-flex mb-4">
                          <FormCheck.Input required id="terms" className="me-2" />
                          <FormCheck.Label htmlFor="terms">
                            J'accepte les <Card.Link as={Link} to={Routes.TermsConditions.path}>termes et conditions</Card.Link>

                          </FormCheck.Label>
                        </FormCheck>

                        <Button variant="primary" type="submit" className="w-100">
                          S'inscrire
                        </Button>
                      </Form>
                  ) : (
                      // Formulaire de vérification du code
                      <Form className="mt-4" onSubmit={handleVerification}>
                        <Form.Group id="verificationCode" className="mb-4">
                          <Form.Label>Code de vérification</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Entrez le code de vérification"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100">
                          Vérifier le compte
                        </Button>
                      </Form>
                  )}

                  {/* Section de connexion par réseau social déplacée */}
                  {!isRegistered && (
                      <>
                        <div className="mt-3 mb-4 text-center">
                          <span className="fw-normal">ou</span>
                        </div>
                        <div className="d-flex justify-content-center my-4">
                          <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                            <FontAwesomeIcon icon={faFacebookF} />
                          </Button>
                          <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                            <FontAwesomeIcon icon={faGoogle} />
                          </Button>
                          {/*<Button variant="outline-light" className="btn-icon-only btn-pill text-dark">*/}
                          {/*  <FontAwesomeIcon icon={faGithub} />*/}
                          {/*</Button>*/}
                        </div>
                      </>
                  )}

                  {/* Lien pour se connecter si l'utilisateur a déjà un compte */}
                  <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Vous avez déjà un compte ?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Connectez-vous ici `}
                    </Card.Link>
                  </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
  );
};
